
import { defineComponent, ref } from 'vue';
import '@/assets/less/index.less';
import setLang from '@/methods/setLang';
import couponDetails from '@/components/view/common/dialog/coupon-details';
import { horizontal, vertical } from '@/components/common/nav/index';
import goBackLogin from '@/router/back-login';
import textPopover from '@/components/common/text-popover/index.vue';
import selectList from '@/components/common/select-list';
import passwordDialog from '@/components/view/common/dialog/password-change';
import setTimeZoneDialog from '@/components/view/common/dialog/time-change';
import billingDialog from '@/components/view/common/dialog/billing-installer';
import { distributorBaseRouter, distributorSubRouter } from '@/router/data';
import useGetTokenStatus from '@/util/use-get-token-status';
import { getBasicInfoDistributor } from '@/api/common/distributor';
import {
    getInitData,
    changeIdentity,
    getDisChargeMode,
    controlPop
} from './util';

export default defineComponent({
    components: {
        horizontal,
        textPopover,
        selectList,
        vertical,
        passwordDialog,
        setTimeZoneDialog,
        billingDialog,
        couponDetails
    },
    setup() {
        useGetTokenStatus();

        const {
            hideAllPop,
            controlPopVal,
            hideOtherPop
        } = controlPop();
        setLang();
        const {
            name,
            showPassWdDialog,
            menus,
            setUserOperaList,
            showSetTimeZone,
            showBilling,
            isShowCouponDetails,
            showCustomerTips
        } = getInitData();
        showCustomerTips();
        const userOperaList = ref<object[]>([]);

        const disMenus = ref<any[]>([]);
        // dis配置：后面迁移到pinal
        const disConfig = {
            isSubDis: false,
            disCanCreateSubDis: false,
            showMacLibraryMenu: true,
            showSubscriptionsMenu: true,
            showPaymentsMenu: false
        };
        // 获取dis配置
        getBasicInfoDistributor({}, (res: any) => {
            console.log(res);
            disConfig.isSubDis = localStorage.getItem('grade') === '12';
            disConfig.disCanCreateSubDis = res.CreateSubDis === 1;
            disConfig.showMacLibraryMenu = localStorage.getItem('grade') !== '12';
            disConfig.showSubscriptionsMenu = res.PayBySubDis === 1;
            if (res.PayBySubDis === 1) {
                disConfig.showPaymentsMenu = true;
            } else if (res.PayBySubDis === 0 && res.HavePayments === 1) {
                // 若它已有支付订单，之后再被关闭支付权限，Payments菜单还在
                disConfig.showPaymentsMenu = true;
            } else if (res.PayBySubDis === 0 && res.HavePayments === 0) {
                // 有支付过的情况下， Pay By Sub Distributor关，此菜单就不显示
                disConfig.showPaymentsMenu = false;
            }
            localStorage.setItem('disConfig', JSON.stringify(disConfig));
            if (disConfig.isSubDis) {
                disMenus.value = menus;
                setMenus();
            } else {
                disMenus.value = menus;
            }
            userOperaList.value = setUserOperaList();
        });

        // 根据dis配置设置菜单
        function setMenus() {
            const libraryIndex = disMenus.value.findIndex((item) => item.path === `/${distributorBaseRouter}/${distributorSubRouter.library}`);
            disMenus.value.splice(libraryIndex, 1);

            if (!disConfig.showSubscriptionsMenu) {
                const subscriptionIndex = disMenus.value
                    .findIndex((item) => item.path === `/${distributorBaseRouter}/${distributorSubRouter.subscription}`);
                disMenus.value.splice(subscriptionIndex, 1);
            }
            if (!disConfig.showPaymentsMenu) {
                const paymentsIndex = disMenus.value.findIndex((item) => item.path === `/${distributorBaseRouter}/${distributorSubRouter.payments}`);
                disMenus.value.splice(paymentsIndex, 1);
            }
        }

        const chooseOpera = (key: string) => {
            hideAllPop();
            switch (key) {
                case 'coupon':
                    isShowCouponDetails.value = true;
                    break;
                case 'password':
                    showPassWdDialog.value = true;
                    break;
                case 'timezone':
                    showSetTimeZone.value = true;
                    break;
                case 'identity':
                    changeIdentity();
                    break;
                case 'bill':
                    showBilling.value = true;
                    break;
                default:
                    break;
            }
        };

        // 获取dis收费模式
        getDisChargeMode();
        return {
            name,
            goBackLogin,
            setUserOperaList,
            userOperaList,
            distributorBaseRouter,
            distributorSubRouter,
            chooseOpera,
            menus,
            disMenus,
            showPassWdDialog,
            showSetTimeZone,
            showBilling,
            isShowCouponDetails,
            controlPopVal,
            hideOtherPop
        };
    }
});
