/* eslint-disable import/prefer-default-export */
import { GetRequest, PostRequest, successParam } from '@/api/base';
//
export function getBasicInfoDistributor(params: object, callback: Function) {
    GetRequest('v3/web/common/distributor/getBasicInfo', params, callback);
}

export function getAllowInstallerDistributor(params: object, callback: Function) {
    GetRequest('v3/web/common/distributor/getAllowInstaller', params, callback);
}
export function addSubDistributor(params: object, callback: any) {
    PostRequest('v3/web/common/subDistributor/add', params, callback);
}
export function editSubDistributor(params: object, callback: any) {
    PostRequest('v3/web/common/subDistributor/edit', params, callback);
}
