import router, {
    distributorBaseRouter,
    distributorSubRouter,
    installerBaseRouter,
    installerSubRouter
} from '@/router';
import { ref, Ref } from 'vue';
import HttpRequest from '@/util/axios.config';
import { account, distributor } from '@/data';
import noticeBase from '@/util/notice.base';

const getInitData = () => {
    const name = String(localStorage.getItem('userName'));
    const showPassWdDialog = ref(false);
    const showSetTimeZone = ref(false);
    const showBilling = ref(false);
    const isShowCouponDetails = ref(false);
    // 如果新增菜单要改下setMenus方法
    const menus = [{
        label: WordList.ProperAllTextProjectDevice,
        name: `${distributorBaseRouter}_${distributorSubRouter.communityDevice}|${distributorBaseRouter}_${
            distributorSubRouter.communityDeviceDetail}`,
        path: `/${distributorBaseRouter}/${distributorSubRouter.communityDevice}`,
        icon: 'el-icon-my-comunity-device'
    }, {
        label: WordList.RDeivicePersonalDevice,
        name: `${distributorBaseRouter}_${distributorSubRouter.personalDevice}|${distributorBaseRouter}_${distributorSubRouter.personalDeviceDetail}`,
        path: `/${distributorBaseRouter}/${distributorSubRouter.personalDevice}`,
        icon: 'el-icon-my-user-device'
    }, {
        label: WordList.ThirdPartyDevices,
        name: `${distributorBaseRouter}_${distributorSubRouter.thirdPartyDevice}`,
        path: `/${distributorBaseRouter}/${distributorSubRouter.thirdPartyDevice}`,
        icon: 'el-icon-my-user-device'
    }, {
        label: WordList.TabelPersonKeyInHtmlUser,
        name: `${distributorBaseRouter}_${distributorSubRouter.user}|${distributorBaseRouter}_${distributorSubRouter.userInfo}|${
            distributorBaseRouter}_${distributorSubRouter.officeInfo}`,
        path: `/${distributorBaseRouter}/${distributorSubRouter.user}`,
        icon: 'el-icon-my-user'
    }, {
        label: WordList.NavInHtmlMenuFirmware,
        name: `${distributorBaseRouter}_${distributorSubRouter.firmware}`,
        path: `/${distributorBaseRouter}/${distributorSubRouter.firmware}`,
        icon: 'el-icon-my-firmware'
    }, {
        label: WordList.NavInHtmlMenuMacLibrary,
        name: `${distributorBaseRouter}_${distributorSubRouter.library}`,
        path: `/${distributorBaseRouter}/${distributorSubRouter.library}`,
        icon: 'el-icon-my-mac-library'
    }, {
        label: WordList.NavInHtmlMenuPurchase,
        name: `${distributorBaseRouter}_${distributorSubRouter.subscription}`,
        path: `/${distributorBaseRouter}/${distributorSubRouter.subscription}`,
        icon: 'el-icon-my-subscription2'
    }, {
        label: WordList.NavInHtmlMenuOrder,
        name: `${distributorBaseRouter}_${distributorSubRouter.payments}|${distributorBaseRouter}_${distributorSubRouter.paymentInfo}`,
        path: `/${distributorBaseRouter}/${distributorSubRouter.payments}`,
        icon: 'el-icon-my-order'
    }, {
        label: WordList.NavInHtmlMenuSet,
        name: '',
        path: '',
        icon: 'el-icon-my-settings',
        children: [{
            label: WordList.ProperAllTextCustomerService,
            name: `${distributorBaseRouter}_${distributorSubRouter.customer}`,
            path: `/${distributorBaseRouter}/${distributorSubRouter.customer}`
        }]
    }];
    function setUserOperaList() {
        const disConfig = JSON.parse(localStorage.getItem('disConfig')!);
        if (disConfig.isSubDis) {
            return [{
                label: WordList.ProperAllTextCouponDetails,
                key: 'coupon'
            }, {
                label: WordList.TabelChangePwTitle,
                key: 'password'
            }, {
                label: WordList.OrderBillingInformation,
                key: 'bill'
            }];
        }
        return [{
            label: WordList.ProperAllTextCouponDetails,
            key: 'coupon'
        }, {
            label: WordList.TabelChangePwTitle,
            key: 'password'
        }, {
            label: WordList.TabelCommunitiesTimeZone,
            key: 'timezone'
        }, {
            label: WordList.ProperAllTextChangeIdentity,
            key: 'identity'
        }, {
            label: WordList.OrderBillingInformation,
            key: 'bill'
        }];
    }

    // dis未设置过客服联系方式，每次登录都会提示
    const showCustomerTips = () => {
        const haveCustomer = localStorage.getItem('haveCustomer');
        // eslint-disable-next-line eqeqeq
        if (haveCustomer === '0') {
            const disAccount = localStorage.getItem('userName')!;
            noticeBase.messageBox('confirm', WordList.ProperAllTextCustomerTips.format(disAccount), WordList.LoginTips, 'warning',
                WordList.ProperAllTextGoToSet, WordList.ProperAllTextIgnore)(() => {
                localStorage.setItem('haveCustomer', '1');
                router.push(`/${distributorBaseRouter}/${distributorSubRouter.customer}`);
            }, () => {
                localStorage.setItem('haveCustomer', '1');
            });
        }
    };

    return {
        name,
        showPassWdDialog,
        menus,
        setUserOperaList,
        showSetTimeZone,
        showBilling,
        isShowCouponDetails,
        showCustomerTips
    };
};

interface IdentityType {
    Token: string;
    Grade: number;
    Account: string;
    TimeZone: string;
    UserName: string;
}

const changeIdentity = () => {
    // 切换installer单住户
    HttpRequest.post('v3/web/common/manage/changeAreaIdentity', {}, [(data: IdentityType) => {
        localStorage.setItem('token', data.Token);
        localStorage.setItem('grade', String(data.Grade));
        localStorage.setItem('account', data.Account);
        localStorage.setItem('userName', data.UserName);
        localStorage.setItem('manageTimeZone', data.TimeZone);
        if (data.Grade === account.installerRole) {
            localStorage.setItem('InstallerLoginType', '0');
            router.push(`/${installerBaseRouter}/${installerSubRouter.user}`);
        } else {
            router.push(`/${distributorBaseRouter}/${distributorSubRouter.communityDevice}`);
        }
    }, false]);
};

// 获取收费模式
const getDisChargeMode = () => {
    distributor.getDisChargeMode((res: {
        ChargeMode: string;
    }) => {
        distributor.ChargeMode.value = Number(res.ChargeMode);
    });
};

interface ControlType {
    opera: boolean;
}
const controlPop = () => {
    const controlPopVal: Ref< ControlType > = ref({
        opera: false
    });
    const hideAllPop = () => {
        controlPopVal.value.opera = false;
    };
    const hideOtherPop = (event: boolean, type: keyof ControlType) => {
        controlPopVal.value[type] = event;
    };
    return {
        hideAllPop,
        controlPopVal,
        hideOtherPop
    };
};

export default null;
export {
    getInitData,
    changeIdentity,
    getDisChargeMode,
    controlPop
};
